@import '~plugin_frontend_core/variables';
@import '~plugin_frontend_core/mixins';

$context:                                   '.content-tile';
$content-tile-color-dark:                   $text-color-primary !default;
$content-tile-color-light:                  $text-color-secondary !default;
$content-carousel-arrow-space-reservation:  40px !default; // Reserves space for content so it doesn't fall underneath carousel arrows
$content-carousel-dots-space-reservation:   20px !default; // Reserves space for content so it doesn't fall underneath carousel dot navigation

#{$context} {
    height: 100%;
    position: relative;

    &--container {
        width: 100%;
    }

    &[href] {
        display: block;
        text-decoration: none;
    }

    img {
        @include resp-img;
    }

    // Color classes
    &--color-light &__body {
        color: $content-tile-color-light;
    }

    &--color-dark &__body {
        color: $content-tile-color-dark;
    }

    // Text-align classes
    &--align-center &__body {
        text-align: center;
    }

    &--align-right &__body {
        text-align: right;
    }

    // Overlay classes
    &--overlay {
        overflow: hidden;
    }

    &--overlay &__body {
        position: absolute;
    }

    &--posx-left &__body {
        left: 0;

        .banner-carousel & {
            left: $content-carousel-arrow-space-reservation;
        }
    }

    &--posx-right &__body {
        right: 0;

        .banner-carousel & {
            right: $content-carousel-arrow-space-reservation;
        }
    }

    &--posx-center &__body {
        left: 50%;
        transform: translateX(-50%);
    }

    &--posy-top &__body {
        top: 0;
    }

    &--posy-bottom &__body {
        bottom: 0;

        .banner-carousel & {
            bottom: $content-carousel-dots-space-reservation;
        }
    }

    &--posy-middle {
        #{$context + '__body'} {
            top: 50%;
            transform: translateY(-50%);
        }

        &#{$context + '--posx-center'} {
            #{$context + '__body'} {
                transform: translate(-50%, -50%);
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .btn {
            font-size: 12px;
        }
    }
}

// This needs to have a height of 100% so that the countdown timer has the same height as other content in row with multiple columns
.experience-asets-contentTile {
    height: 100%;

    .experience-layouts-1column & {
        height: auto;
    }
}
